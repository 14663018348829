<template>
  <div class="relative bg-white">
    <div class="px-4 sm:px-6">
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <nav class="hidden space-x-10 md:flex">
          <router-link
            :to="{ name: 'HomeAuction' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Home Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'AuctionsDetail' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Detalle Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'ListOfAuctions' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Lista de Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'AuctionFilter' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Filtro de Subastas</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step1' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 1</span>
          </router-link>
          <router-link
            :to="{ name: 'Step2' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 2</span>
          </router-link>
          <router-link
            :to="{ name: 'Step3' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 3</span>
          </router-link>
          <router-link
            :to="{ name: 'Step4' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 4</span>
          </router-link>
          <router-link
            :to="{ name: 'Step5' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 5</span>
          </router-link>
          <router-link
            :to="{ name: 'Step6' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 6</span>
          </router-link>
          <router-link
            :to="{ name: 'Step7' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 7</span>
          </router-link>
          <router-link
            :to="{ name: 'Step8' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 8</span>
          </router-link>
          <router-link
            :to="{ name: 'Step9' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 9</span>
          </router-link>
          <router-link
            :to="{ name: 'Step10' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500">Paso 10</span>
          </router-link>
        </nav>
      </div>
      <div
        class="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <nav class="hidden space-x-10 md:flex">
          <router-link
            :to="{ name: 'Step1GeneralAuctionRules' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 1</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2EnterDataNaturalPerson' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2EnterDataLegalPerson' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.1</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerification' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.2</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationDataIncorrect' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.3</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationSuccessful' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.4</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step2DataVerificationRejected' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 2.5</span
            >
          </router-link>
          <router-link
            :to="{ name: 'Step3WithholdingTax' }"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            <span class="font-nexa text-xs mt-1 text-gray-500"
              >Inscribir Subastas Pasos 3</span
            >
          </router-link>
        </nav>
      </div>
    </div>
  </div>
  <header class="bg-agrogo-green-1 px-4 space-x-2">
    <div class="container-header">
      <div class="myPanel md:w-52" v-if="!activeSearchDesktop">
        <button
          class="text-agrogo-yellow-1 hover:text-white flex items-center myPanel"
          @click="activeMenu()"
        >
          <span class="icon-BurguerMenu text-lg lg:text-2xl myPanel"></span>
        </button>
      </div>
      <div class="myPanel" v-else>
        <button
          class="text-agrogo-yellow-1 hover:text-white flex items-center myPanel"
          @click="activeMenu()"
        >
          <span class="icon-BurguerMenu text-lg lg:text-2xl myPanel"></span>
        </button>
      </div>

      <router-link class="flex" to="/"
        ><img
          class="max-h-7 lg:max-h-10"
          srcset="@/assets/images/imagesHeader/agrogosubastas.svg"
          src="@/assets/images/imagesHeader/agrogosubastas.png"
          alt="AGROGO Fincas"
      /></router-link>
      <div class="flex md:hidden">
        <button
          class="text-agrogo-yellow-1 flex flex-col pr-2 hover:text-white items-center justify-center"
          @click.prevent="publish"
        >
          <span class="icon-subastas text-2xl text-center"></span>
          <span class="font-nexa text-xs mt-1 text-white">Fincas</span>
        </button>
        <button
          class="text-agrogo-yellow-1 flex flex-col pl-2 hover:text-white items-center justify-center"
          @click.prevent="myAccount"
        >
          <span class="icon-Perfil text-2xl"></span>
          <span class="font-nexa text-xs mt-1 text-white">
            {{ isAuthenticated ? "Mi cuenta" : "Ingresar" }}
          </span>
        </button>
      </div>
      <!-- barra de busqueda escritorio-->
      <div class="hidden md:flex md:flex-row" v-if="activeSearchDesktop">
        <input type="search" class="search-bar" placeholder="Buscar" />
        <span class="icon-search-bar"></span>
      </div>
      <div class="hidden md:flex lg:flex">
        <button
          class="text-agrogo-yellow-1 flex flex-row pr-2 items-center hover:text-white"
          @click.prevent="publish"
        >
          <span class="font-nexa text-base text-white pr-2">Fincas</span>
          <span class="icon-fincas text-2xl xl:mr-4"></span>
        </button>
        <button
          class="text-agrogo-yellow-1 flex flex-row items-center hover:text-white"
          @click.prevent="myAccount"
        >
          <span class="font-nexa text-base pr-2 text-white">
            {{ isAuthenticated ? "Mi cuenta" : "Ingresar" }}
          </span>
          <span class="icon-Perfil text-2xl"></span>
        </button>
      </div>
    </div>
    <!-- barra de busqueda movil-->
    <div class="flex justify-center md:hidden pb-4" v-if="activeSearchMobile">
      <input
        type="search"
        class="search-bar w-11/12 sm:w-4/5"
        placeholder="Buscar"
      />
      <span class="icon-search-bar"></span>
    </div>
  </header>
  <Menu v-if="stateMenu" @close="stateMenu = false" />
  <div class="relative bg-white hidden">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <a href="#">
            <span class="sr-only">Workflow</span>
            <px-icon />
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav class="hidden md:flex space-x-10" aria-label="">
          <router-link
            to="/"
            class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >Home</router-link
          >
          <a
            href="#"
            class="text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Docs
          </a>
        </nav>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <router-link
            to="/inicio-de-sesion"
            class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
            >Login</router-link
          >
          <router-link
            to="/registro"
            class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >Register</router-link
          >
        </div>
      </div>
    </div>
    <div
      class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div
        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
      >
        <div class="py-6 px-5 space-y-6">
          <div class="grid grid-cols-2 gap-y-4 gap-x-8">
            <a
              href="#"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Pricing
            </a>

            <a
              href="#"
              class="text-base font-medium text-gray-900 hover:text-gray-700"
            >
              Docs
            </a>
          </div>
          <div>
            <a
              href="#"
              class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Sign up
            </a>
            <p class="mt-6 text-center text-base font-medium text-gray-500">
              Existing customer?
              <a href="#" class="text-indigo-600 hover:text-indigo-500">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./Menu";
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";

export default {
  inject: ["provider"],
  name: "PxHeaderAuction",
  data() {
    return {
      /**
       * @type {Boolean}
       */
      stateMenu: false,
      /**
       * @type {provider}
       */
      repository: this.provider.leadRepository,
      /**
       * @type {Boolean}
       */
      isBusy: false,
    };
  },
  props: {
    msg: String,
    activeSearchDesktop: {
      type: Boolean,
      default: true,
    },
    activeSearchMobile: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Menu,
  },
  computed: {
    ...mapState("user", ["isAuthenticated"]),
  },
  methods: {
    ...mapActions("farmTypes", ["loadFarmTypes"]),
    /**
     *
     * method that validates if the user is logged in to redirect to my-count or login
     * @returns  {void}
     */
    myAccount() {
      const me = this;
      if (me.isAuthenticated) {
        me.$router.push({
          name: "mi-cuenta",
        });
      } else {
        me.$router.push({
          name: "Login",
          query: { redirect: "mi-cuenta" },
        });
      }
    },
    /**
     *
     * method that validates if the user is logged in to redirect to login or publish
     * @returns  {void}
     */
    publish() {
      const me = this;
      if (me.isAuthenticated) {
        me.$router.push({
          name: "publicar",
        });
      } else {
        me.$router.push({
          name: "Login",
          query: { redirect: "publicar" },
        });
      }
    },
    /**
     * Toggles the menu component on and off.
     * @returns  {void}
     */
    activeMenu() {
      if (this.stateMenu) {
        this.stateMenu = false;
      } else {
        this.stateMenu = true;
      }
    },
    /**
     * Deactivates the menu when pressing outside the el
     * @returns  {void}
     */
    closeMenuDocument() {
      document.addEventListener("click", (e) => {
        if (!e.target.className.includes("myPanel")) {
          this.stateMenu = false;
        }
      });
    },
    /**
     * consult the api that of categories
     * @returns  {void}
     */
    async loadCategories() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAll("1", dataAditional);
        me.loadFarmTypes(data);
      } catch (error) {
        console.log(error);
      } finally {
        me.isBusy = false;
      }
    },
  },
  mounted() {
    this.loadCategories();
    this.closeMenuDocument();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
